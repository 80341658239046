import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Http, Headers, RequestOptions } from "@angular/http";
import { constants } from "../../config/constants";
import { apis } from "../../config/apis";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Options } from "selenium-webdriver/opera";

@Injectable({
  providedIn: "root"
})


export class CrudService {

  loggedUser = false;

  constructor(private http: HttpClient) {

  }



  public post(api, postdata) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        // 'Authorization': 'my-auth-token'
      })
    };
    return this.http.post("https://carter-cors.herokuapp.com/" + environment.baseUrl + api, postdata, httpOptions);
  }

  public get(api) {
    return this.http.get("https://carter-cors.herokuapp.com/" + environment.baseUrl + api);
  }

  public update(api, data) {
    return this.http.post(environment.baseUrl + api, data);
  }

  public delete(api, data) {
    return this.http.delete(environment.baseUrl + api, data);
  }

  public postJson(api, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=UTF-8",
        // 'Authorization': 'my-auth-token'
      })
    };
    return this.http.post(environment.baseUrl + api, data, httpOptions);
  }

  public postFormdata(api, postdata) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "text/html; charset=UTF-8",
        // 'Authorization': 'my-auth-token'
      })
    };
    return this.http.post(environment.baseUrl + api, postdata, httpOptions);
  }

  public postWithStaticToken(api, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        //* For Production
        // **********************************************************
        "token": `3ee669e09fd7a5fca01f34c1749af398`,

        //* For QA
        // **********************************************************
        // "token": `f2760b33d46a55283b650a600e535ca6`
      })
    };
    return this.http.post("https://carter-cors.herokuapp.com/" + environment.baseUrl + api, data, httpOptions);
  }

}

